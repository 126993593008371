import ApiException from '@components/exceptions/ApiExceptions'
import axios from 'axios'
import * as providersSlice from '../store/ServiceProvidersSlice'
import { IScheduleObject } from '@components/service-providers/ServiceProviderSchedule'
interface Error {
  response: {
    status: number
  }
}

interface ProviderServices {
  provider_id: number
  service_id: number
}
interface ProviderBranches {
  branch_id: number
  provider_id: number
}

export const getAllProviders = async (offset?: number, limit?: number) => {
  try {
    if (limit || offset) {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/providers?offset=${offset}&limit=${limit}`,
      )
      return result.data
    } else {
      // This is only temporary fix. DB find will be longer term feature
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/providers?offset=0&limit=300`,
      )
      return result.data
    }
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}
export const getProvider = async (id: number) => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/providers/${id}`)
    return result.data
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}
export const createProvider = async (params: providersSlice.Provider) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/providers`, params)
    return result.data
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}
export const uploadImage = async (id: string) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/providers/${id}/uploadfile`,
    )
    return result.data
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}
export const updateProvider = async (params: providersSlice.Provider) => {
  try {
    const result = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/providers/${params.id}`,
      params,
    )
    return result.data
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}
export const updateProviderServices = async (params: ProviderServices) => {
  try {
    const result = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/providerServices`, params)
    return result.data
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}
export const updateProviderBranches = async (params: ProviderBranches) => {
  try {
    const result = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/providersBranches`, params)
    return result.data
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}
export const deleteProvider = async (params: providersSlice.Provider) => {
  try {
    const result = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/providers/${params.id}`)
    return result
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}
export const deleteProviderService = async (params: ProviderServices) => {
  try {
    const result = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/providers/${params.provider_id}/services/${params.service_id}`,
    )
    return result
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}
export const deleteProviderBranch = async (params: ProviderBranches) => {
  try {
    const result = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/providers/${params.provider_id}/branches/${params.branch_id}`,
    )
    return result
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}

export const countProviders = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/count_providers`)
    return result.data
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}

export const getSchedules = async (providerId: number) => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/providers/${providerId}/schedules`,
    )
    return result.data
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}

export const createSchedule = async (
  providerId: number,
  schedule: IScheduleObject,
) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/providers/${providerId}/schedules`,
      schedule,
    )
    return result.data
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}

export const updateSchedule = async (
  providerId: number,
  scheduleId: number,
  schedule: {is_active: boolean, write_username: string},
) => {
  try {
    const result = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/admin/providers/${providerId}/schedules/${scheduleId}`,
      schedule,
    )
    return result.data
  } catch (error) {
    const typedError = error as Error
    throw new ApiException(typedError.response.status)
  }
}