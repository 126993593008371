import { useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { useQuery } from '@tanstack/react-query'
import * as providerServices from '../../services/providerServices'
import { useSelector } from 'react-redux'
import { AppState } from 'src/store'
import LoadingSpinner from '@components/loading/LoadingSpinner'
import * as texts from '../exceptions/texts'
import Schedule from './Schedule'

export interface IScheduleObject {
  day_of_week: number
  time: string
  shift?: string
  create_username?: string
  write_username?: string
  id?: number
}

const ServiceProviderSchedule = () => {
  const handleHeaderLabel = (index: number) => {
    if (index === 0) return { left: '-2%' }
    else if (index === 12) return { left: '49%' }
    else if (index === 13) return { left: '50%' }
    else if (index > 13 && index < 25) return { left: `${(100 / 24) * (index - 1) * 0.997}%` }
    else if (index === 25) return { left: '99%' }
    else return { left: `${(100 / 24) * index * 0.997}%` }
  }

  const selectedProvider = useSelector((state: AppState) => state.providers.selectedProvider)

  const [schedules, setSchedules] = useState<IScheduleObject[]>([])
  const [createSchedule, setCreateSchedule] = useState(false)

  const updateSchedules = (mySchedules: IScheduleObject[]) => {
    const sortedSchedules = sortSchedules(mySchedules)
    setSchedules(sortedSchedules)
    setCreateSchedule(false)
  }


  const sortSchedules = (mySchedules: IScheduleObject[]) => {
    // sort the returned schedules based on day_of_week and time.
    return mySchedules.sort(
      (a: { day_of_week: number; time: string }, b: { day_of_week: number; time: string }) => {
        // First, compare by day_of_week
        if (a.day_of_week !== b.day_of_week) {
          return a.day_of_week - b.day_of_week
        }
        // If day_of_week is the same, compare by time
        return a.time.localeCompare(b.time)
      },
    )
  }

  const { isLoading, isSuccess, isError } = useQuery(
    ['provider-schedule', selectedProvider?.id],
    () => {
      return providerServices.getSchedules(selectedProvider.id as number)
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCreateSchedule(false)
        const sortedSchedules = sortSchedules(data)
        setSchedules(sortedSchedules)
      },
      enabled: !!selectedProvider.id,
    },
  )

  return (
    <div className='provider-schedule-section'>
      <div className='headline ms-3 mb-2'>Provider Schedule</div>
      {isLoading && (
        <div className='text-center'>
          <LoadingSpinner />
        </div>
      )}
      {isError && (
        <Alert variant='light' className='label m-0 text-danger'>
          <i className='eha-level4 text-danger me-2' />
          {texts.FETCHING_FAILED}
        </Alert>
      )}
      {createSchedule && (
        <Schedule
          handleHeaderLabel={handleHeaderLabel}
          updateSchedules={updateSchedules}
          schedules={schedules}
        />
      )}
      {isSuccess && schedules.length === 0 && createSchedule === false && (
        <div className='provider-schedule-card'>
          <div>No schedule created for this provider</div>
          <div className='mt-3'>
            <Button onClick={() => setCreateSchedule(true)}>Create</Button>
          </div>
        </div>
      )}
      {isSuccess && schedules.length > 0 && (
        <Schedule
          handleHeaderLabel={handleHeaderLabel}
          updateSchedules={updateSchedules}
          schedules={schedules}
        />
      )}
    </div>
  )
}

export default ServiceProviderSchedule