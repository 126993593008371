import React, { useState } from 'react';
import { Calendar, dateFnsLocalizer, ToolbarProps, View } from 'react-big-calendar';
import { format, startOfWeek, getDay } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './_providerCalendar.scss'; 

const locales = { 'en-US': enUS };
const localizer = dateFnsLocalizer({
  format,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
});

const CustomToolbar: React.FC<ToolbarProps> = ({ label, onNavigate, onView }) => {
  const [activeView, setActiveView] = useState('month');

  const handleViewChange = (view: View) => {
    setActiveView(view);
    onView(view);
  };

  return (
    <div className="rtb-toolbar">
      <div className="rtb-toolbar-top">
        <p>Set Schedule</p>
        <div className="rtb-cal-btn">
          <button
            type="button"
            className={`rbc-btn ${activeView === 'day' ? 'active' : ''}`}
            onClick={() => handleViewChange('day')}
          >
            Day
          </button>
          <button
            type="button"
            className={`rbc-btn ${activeView === 'week' ? 'active' : ''}`}
            onClick={() => handleViewChange('week')}
          >
            Week
          </button>
          <button
            type="button"
            className={`rbc-btn ${activeView === 'month' ? 'active' : ''}`}
            onClick={() => handleViewChange('month')}
          >
            Month
          </button>
        </div>
      </div>
      <div className="rtb-navigation">
        <button className="rtb-nav-button" onClick={() => onNavigate('PREV')}>
          <span className='rtb-nav-icon'>&#8249;</span>
          Prev
        </button>
        <span className='rtb-toolbar-label'>{label}</span>
        <button className="rtb-nav-button" onClick={() => onNavigate('NEXT')}>
          Next
          <span className='rtb-nav-icon'>&#8250;</span>
        </button>
      </div>
    </div>
  );
};

const ProviderCalendar: React.FC = () => {
  return (
    <div>
      <Calendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 585 }}
        selectable
        defaultView="month"
        views={['month', 'week', 'day']}
        components={{ toolbar: CustomToolbar }}
      />
    </div>
  );
};

export default ProviderCalendar;