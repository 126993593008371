import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setShowProviderCalendarModal } from '../../store/UpdateBookingSlice';
import './_providerCalendar.scss';
import RescheduleCalendar from '../service-providers/ProviderCalendar';
import ProviderWeekendToggleButton from '../service-providers/ProviderWeekendToggleButton';

interface ProviderCalendarModalProps {
  show: boolean;
  onHide: () => void;
}

const ProviderCalendarModal: React.FC<ProviderCalendarModalProps> = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const handleHide = () => {
    dispatch(setShowProviderCalendarModal(false));
    onHide();
  };

  return (
    <Modal
      size="xl"
      show={show}
      backdrop="static"
      enforceFocus={false}
      backdropClassName="front"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable
      centered
      onHide={handleHide}
      dialogClassName="custom-modal-size"
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">Set Availability for Lawrence Ewa</Modal.Title>
      </Modal.Header>
      <div className='toggle-container'>
        <p>Toggle to show/Hide</p>
        <div>
        <ProviderWeekendToggleButton/>
        </div>
      </div>
      <Modal.Body>
        <RescheduleCalendar />
        <div className="button-container">
          <Button variant="light" onClick={handleHide}>
            Cancel
          </Button>
          <Button variant="primary" className="btn-save">
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProviderCalendarModal;