import { useState } from 'react';
import './_providerCalendar.scss'; 

const ProviderWeekendToggleButton = () => {
  const [toggleSaturday, setToggleSaturday] = useState(false);
  const [toggleSunday, setToggleSunday] = useState(false);

  const handleSaturdayClick = () => {
    setToggleSaturday(!toggleSaturday);
  };

  const handleSundayClick = () => {
    setToggleSunday(!toggleSunday);
  };

  return (
    <div>
      <div className='toggle-button'>
        <div 
          onClick={handleSaturdayClick} 
          className={`toggle ${toggleSaturday ? 'toggle-active' : ''}`}
        >
          {toggleSaturday ?
            <div className='toggle-left'></div> :
            <div className='toggle-right'></div>} 
        </div>
        <p>Hide Saturday</p>
      </div>

      <div className='toggle-button2'>
        <div 
          onClick={handleSundayClick} 
          className={`toggle2 ${toggleSunday ? 'toggle-active2' : ''}`}
        >
          {toggleSunday ?
            <div className='toggle-left2'></div> :
            <div className='toggle-right2'></div>} 
        </div>
        <p>Hide Sunday</p>
      </div>
    </div>
  );
};

export default ProviderWeekendToggleButton;